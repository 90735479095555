export default function sidebarApp() {
  return {
    opened: window.localStorage.getItem('sideMenuOpened') == 'true' || false,
    preference: window.localStorage.getItem('sideMenuOpened') == 'true' || false,
    setPreference: function (value) {
      window.localStorage.setItem('sideMenuOpened', value)
      this.preference = value
      this.opened = value
    },
    getPreference: function () {
      return window.localStorage.getItem('sideMenuOpened')
    },
    mouseOver: function () {
      this.opened = true
    },
    mouseLeave: function () {
      if (window.localStorage.getItem('sideMenuOpened') == 'false') {
        this.opened = false
      }
    }
  }
}
